<template>
  <va-card title="Translations">
    <va-data-table
      :fields="fields"
      :data="items"
      :per-page="25"
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="translations" slot-scope="props">
        <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          small
          color="info"
          :to="{ name: 'shkil-settings-translations-edit', params: { id: props.rowData.id } }"
        >
          Редагувати
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'id',
        width: '10%',
      },
      {
        name: 'message',
        title: 'Message',
        width: '25%',
      },
      {
        name: 'category',
        title: 'Category',
        width: '15%',
      },
      {
        title: 'Переклади',
        name: '__slot:translations',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/translation?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.forEach((item) => {
            item.published = !!item.published
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
